.anim-in-out.anim-in-setup.anim-fade, .anim-in-out.anim-in-setup .anim-fade {
  opacity: 0; }

.anim-in-out.anim-in-setup.anim-slide-left, .anim-in-out.anim-in-setup .anim-slide-left {
  -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
          transform: translateX(100%); }

.anim-in-out.anim-in-setup.anim-slide-right, .anim-in-out.anim-in-setup .anim-slide-right {
  -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
          transform: translateX(-100%); }

.anim-in-out.anim-in-setup.anim-slide-below, .anim-in-out.anim-in-setup .anim-slide-below {
  -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
          transform: translateY(20px); }

.anim-in-out.anim-in-setup.anim-slide-below-fade, .anim-in-out.anim-in-setup .anim-slide-below-fade {
  opacity: 0;
  -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
          transform: translateY(20px); }

.anim-in-out.anim-in-setup.anim-zoom-out, .anim-in-out.anim-in-setup .anim-zoom-out {
  -webkit-transform: scale(0.95);
      -ms-transform: scale(0.95);
          transform: scale(0.95); }

.anim-in-out.anim-in-setup.anim-zoom-in, .anim-in-out.anim-in-setup .anim-zoom-in {
  -webkit-transform: scale(1.025);
      -ms-transform: scale(1.025);
          transform: scale(1.025); }

.anim-in-out.anim-in-setup.anim-zoom-out-full, .anim-in-out.anim-in-setup .anim-zoom-out-full {
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0); }

.anim-in-out.anim-in-setup.anim-zoom-in-full, .anim-in-out.anim-in-setup .anim-zoom-in-full {
  -webkit-transform: scale(10);
      -ms-transform: scale(10);
          transform: scale(10); }

.anim-in-out.anim-in.anim-fade, .anim-in-out.anim-in .anim-fade {
  opacity: 1;
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-in.anim-slide-left, .anim-in-out.anim-in .anim-slide-left {
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-in.anim-slide-right, .anim-in-out.anim-in .anim-slide-right {
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-in.anim-slide-below, .anim-in-out.anim-in .anim-slide-below {
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-in.anim-slide-below-fade, .anim-in-out.anim-in .anim-slide-below-fade {
  opacity: 1;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-in.anim-zoom-out, .anim-in-out.anim-in .anim-zoom-out {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-in.anim-zoom-in, .anim-in-out.anim-in .anim-zoom-in {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-in.anim-zoom-out-full, .anim-in-out.anim-in .anim-zoom-out-full {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-in.anim-zoom-in-full, .anim-in-out.anim-in .anim-zoom-in-full {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out-setup.anim-fade, .anim-in-out.anim-out-setup .anim-fade {
  opacity: 1;
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out-setup.anim-slide-left, .anim-in-out.anim-out-setup .anim-slide-left {
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out-setup.anim-slide-right, .anim-in-out.anim-out-setup .anim-slide-right {
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out-setup.anim-slide-below, .anim-in-out.anim-out-setup .anim-slide-below {
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out-setup.anim-slide-below-fade, .anim-in-out.anim-out-setup .anim-slide-below-fade {
  opacity: 1;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out-setup.anim-zoom-out, .anim-in-out.anim-out-setup .anim-zoom-out {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out-setup.anim-zoom-in, .anim-in-out.anim-out-setup .anim-zoom-in {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out-setup.anim-zoom-out-full, .anim-in-out.anim-out-setup .anim-zoom-out-full {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out-setup.anim-zoom-in-full, .anim-in-out.anim-out-setup .anim-zoom-in-full {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out.anim-swap, .anim-in-out.anim-out .anim-swap {
  display: none; }

.anim-in-out.anim-out.anim-fade, .anim-in-out.anim-out .anim-fade {
  opacity: 0;
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out.anim-slide-left, .anim-in-out.anim-out .anim-slide-left {
  -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out.anim-slide-right, .anim-in-out.anim-out .anim-slide-right {
  -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out.anim-slide-below, .anim-in-out.anim-out .anim-slide-below {
  -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out.anim-slide-below-fade, .anim-in-out.anim-out .anim-slide-below-fade {
  opacity: 0;
  -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out.anim-zoom-out, .anim-in-out.anim-out .anim-zoom-out {
  -webkit-transform: scale(0.95);
      -ms-transform: scale(0.95);
          transform: scale(0.95);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out.anim-zoom-in, .anim-in-out.anim-out .anim-zoom-in {
  -webkit-transform: scale(1.025);
      -ms-transform: scale(1.025);
          transform: scale(1.025);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out.anim-zoom-out-full, .anim-in-out.anim-out .anim-zoom-out-full {
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 500ms;
          transition: all 500ms; }

.anim-in-out.anim-out.anim-zoom-in-full, .anim-in-out.anim-out .anim-zoom-in-full {
  -webkit-transform: scale(10);
      -ms-transform: scale(10);
          transform: scale(10);
  -webkit-transition: all 500ms;
          transition: all 500ms; }
